import { Suspense } from "react";
import { useEffect } from "react";
// import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routers } from "./routers";
import { FrontPage } from "./pages";
import "@/styles/globals.scss";
import "antd-mobile/dist/antd-mobile.css";

require("amfe-flexible/index");
const wx = require("weixin-js-sdk");
function App() {
	useEffect(() => {
		wx.hideMenuItems({
			menuList: [
				"menuItem:share:timeline",
				"menuItem:copyUrl",
				"menuItem:share:appMessage",
				"menuItem:share:qq",
				"menuItem:share:weiboApp",
				"menuItem:favorite",
				"menuItem:share:facebook",
				"menuItem:share:QZone",
				"menuItem:editTag",
				"menuItem:delete",
				"menuItem:copyUrl",
				"menuItem:originPage",
				"menuItem:readMode",
				"menuItem:openWithQQBrowser",
				"menuItem:openWithSafari",
				"menuItem:share:email",
				"menuItem:share:brand",
			], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
		});
	}, []);
	return (
		<Router>
			<Switch>
				{/* Suspense配合lazy使用 */}
				<Suspense fallback={<div></div>}>
					{Routers.map((router) => (
						<Route
							key={router.path}
							exact={!router.notExect}
							path={router.path}
							component={router.component}
						></Route>
					))}
					{/* 默认重定向到登录页 */}
					<Route path="/" component={FrontPage} exact></Route>
				</Suspense>
			</Switch>
		</Router>
	);
}
export default App;
